class OpeningBox {
    constructor(element) {
        this.element = element;
        this.trigger = element.querySelector('.opening-box__toggle');
        this.target = element.querySelector('.opening-box__target');
        this.arrowType = this.getArrowType();

        if (this.trigger && this.target) {
            this.trigger.addEventListener('click', (e) => {
                e.preventDefault();

                if (this.isOpen()) {
                    this.close();
                } else {
                    this.open();
                }
            });
        }
    }

    close() {
        this.element.classList.remove('open');

        // optionnal arrow class change
        const arrows = this.trigger.querySelectorAll('.icon_arrowdown');
        Array.from(arrows).forEach((arrow) => {
            arrow.classList.remove('icon_arrowdown');
            arrow.classList.add(this.arrowType);
        });
    }

    open() {
        this.element.classList.add('open');

        // optionnal arrow class change
        const arrows = this.trigger.querySelectorAll(`.${this.arrowType}`);
        Array.from(arrows).forEach((arrow) => {
            arrow.classList.remove(this.arrowType);
            arrow.classList.add('icon_arrowdown');
        });
    }

    isOpen() {
        return this.element.classList.contains('open');
    }

    getArrowType() {
        return this.element.classList.contains('opening-box_arrowright') ? 'icon_arrowright' : 'icon_arrowleft';
    }
}

document.addEventListener('DOMContentLoaded', () => {
    Array.from(document.querySelectorAll('.opening-box')).map((element) => {
        return new OpeningBox(element);
    });
});
