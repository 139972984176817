import './date.scss';

import SeeMore from 'js/seeMore'; // eslint-disable-line

document.addEventListener('DOMContentLoaded', () => {
    const seeMore = new SeeMore('.form-date.opening-box', 3);
    const seeMoreButton = document.getElementById('seeMoreButton');

    seeMore.showMoreItems();
    if (seeMore.noMoreItems() && seeMoreButton) {
        seeMoreButton.parentNode.removeChild(seeMoreButton); // fix IE
    }

    if (seeMoreButton) {
        seeMoreButton.addEventListener('click', () => {
            seeMore.showMoreItems();
            if (seeMore.noMoreItems() && seeMoreButton) {
                seeMoreButton.parentNode.removeChild(seeMoreButton); // fix IE
            }
        });
    }
});
