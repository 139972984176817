class Menu {
    constructor() {
        this.openState = false;
        this.openStateSub = false;
        this.$root = null;
        this.$rootBack = null;
        this.$sub = null;
        this.$menu = null;
        this.$menuItems = null;
        this.$secondaryMenu = null;
        this.$openedSubMenu = null;

        this.init();
    }

    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.$root = document.getElementById('mainNav');
            this.$rootBack = document.getElementById('mainNavBack');
            this.$menuItems = this.$root.querySelectorAll('.main-menu__item:not(.submenu__item)');
            this.$sub = this.$root.querySelectorAll('.main-menu__item_havesubmenu');
            this.$secondaryMenu = this.$root.querySelector('.secondary-menu');

            const triggers = document.getElementsByClassName('menu-trigger');
            Array.from(triggers).forEach((node) => {
                node.addEventListener('click', this.toggle.bind(this));
            });

            Array.from(this.$sub).forEach((menu) => {
                const subMenuButton = menu.querySelector('button');
                const currentSubMenu = menu.querySelector('.main-menu__submenu');
                subMenuButton.addEventListener('click', this.toggleSub.bind(this, currentSubMenu));
            });
        });
    }

    open() {
        // console.info('open')
        this.openState = true;
        this.$root.classList.add('active');
    }

    openSub(currentSubMenu, button) {
        this.openStateSub = true;
        this.$openedSubMenu = currentSubMenu;
        this.$rootBack.classList.add('main-nav__background_rotate');
        this.$secondaryMenu.classList.add('secondary-menu_animationfast');
        currentSubMenu.classList.add('submenu_show');
        button.classList.add('submenu__button_title');
        this.$menuItems.forEach((menu) => {
            if (menu !== button.parentElement) {
                menu.classList.add('main-menu__item_hide');
            }
        });

        button.parentElement.classList.add('main-menu__item_show');
        this.$secondaryMenu.classList.add('secondary-menu_hide');
    }

    close() {
        // console.info('close')
        this.openState = false;
        this.$secondaryMenu.classList.remove('secondary-menu_animationfast');
        this.$root.classList.remove('active');
        const button = this.$root.querySelector('.submenu__button_title');
        if (button) {
            this.closeSub(this.$openedSubMenu, button);
        }
    }

    closeSub(currentSubMenu, button) {
        this.openStateSub = false;
        this.$rootBack.classList.remove('main-nav__background_rotate');
        currentSubMenu.classList.remove('submenu_show');
        button.classList.remove('submenu__button_title');
        this.$menuItems.forEach((menu) => {
            menu.classList.remove('main-menu__item_hide');
        });
        button.parentElement.classList.remove('main-menu__item_show');
        this.$secondaryMenu.classList.remove('secondary-menu_hide');
    }

    toggle(e) {
        e.stopPropagation();
        if (this.openState) {
            this.close();
        } else {
            this.open();
        }
    }

    toggleSub(currentSubMenu, e) {
        e.stopPropagation();
        if (this.openStateSub) {
            this.closeSub(currentSubMenu, e.target);
        } else {
            this.openSub(currentSubMenu, e.target);
        }
    }

    isOpen() {
        return this.openState;
    }

    isOpenSub() {
        return this.openStateSub;
    }
}

export default Menu;
