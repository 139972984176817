import Popup from './Popup';

// Loads all available popup elements in DOM
document.addEventListener('DOMContentLoaded', () => {
    window.openPopinGTM = (id) => { //eslint-disable-line
        const elmt = document.getElementById(id);
        const popup = new Popup(elmt);
        return popup;
    };
});
