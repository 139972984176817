import anime from 'animejs';

class Popup {
    /**
     * @param {Node} node
     */
    constructor(node) {
        this.$root = node;
        this.$content = null;
        this.openState = false;

        this.duration = -1;
        this.timeout = -1;
        this.showonce = false;

        this.init();
    }

    init() {
        this.$content = this.$root.querySelector('.popup__illustration');
        this.$notif = this.$root.querySelector('.popup__illustration_oval');
        this.$cross = this.$root.querySelector('#closePopup');

        this.$cross.addEventListener('click', () => {
            // if (e.target === this.$root) {
            this.close();
            // }
        });

        const {
            duration = 2500,
            delay = 0,
            showonce = false,
        } = JSON.parse(this.$root.dataset.popup);

        this.duration = this.duration === -1 ? -1 : parseInt(duration, 10);
        this.delay = parseInt(delay, 10);
        this.showonce = showonce;

        const shown = parseInt(localStorage.getItem(`popup:${this.$root.id}`), 10);

        if (!this.showonce || !shown) {
            this.open(this.delay);
        }
    }

    /**
     * @param {number} delay
     */
    open(delay = 0) {
        if (this.openState) {
            return;
        }

        setTimeout(() => {
            this.openState = true;

            this.$root.style.transform = 'translateY(100%)';
            this.$root.classList.add('active');

            this.$content.style.transform = 'scale(0)';
            this.$notif.style.transform = 'scale(0)';

            const containerAnim = anime({
                translateY: 0,
                targets: this.$root,
                easing: 'easeOutExpo',
                duration: 1000,
            });

            const contentAnim = anime({
                targets: this.$content,
                scale: 1,
                easing: 'easeOutExpo',
                duration: 250,
                delay: 500,
            });

            const notifAnim = anime({
                targets: this.$notif,
                scale: 1,
                easing: 'easeOutExpo',
                duration: 350,
                delay: 3000,
            });

            Promise.all([containerAnim.finished, contentAnim.finished, notifAnim.finished]).then(() => {
                // shown once
                localStorage.setItem(`popup:${this.$root.id}`, 1);

                // handle close
                if (this.duration > 0) {
                    this.timeout = setTimeout(() => {
                        this.close();
                    }, this.duration);
                }
            });
        }, delay);
    }

    close() {
        const containerAnim = anime({
            targets: this.$root,
            opacity: 0,
            easing: 'easeOutBack',
            duration: 350,
        });

        containerAnim.finished.then(() => {
            clearTimeout(this.timeout);

            this.openState = false;

            this.$root.classList.remove('active');
        });
    }

    isOpen() {
        return this.openState;
    }
}

export default Popup;
