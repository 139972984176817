import ObjectAssignDeep from 'object-assign-deep'; // eslint-disable-line
// import zxcvbn from '@novembrecom/zxcvbn'; // eslint-disable-line
import isEmail from 'validator/lib/isEmail';
import normalizeEmail from 'validator/lib/normalizeEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isPostalCode from 'validator/lib/isPostalCode';
import escape from 'validator/lib/escape';
import passwordMessages from './passwordMessages.json'; // eslint-disable-line
import MESSAGES from './messages.json'; // eslint-disable-line


// const getStrength = (score) => {
//     switch (score) {
//     case 2:
//         return {
//             color: '#f4511e',
//             message: 'Mot de passe peu sécurisé',
//         };
//     case 3:
//         return {
//             color: '#f9a825',
//             message: 'Mot de passe sécurisé',
//         };
//     case 4:
//         return {
//             color: '#2e7d32',
//             message: 'Mot de passe très sécurisé',
//         };
//     default:
//         return {
//             color: '#b71c1c',
//             message: 'Mot de passe non sécurisé',
//         };
//     }
// };

// const setMessageColor = ($field, color) => {
//     const $message = $field.parentElement.parentElement.querySelector('.form-message');
//     if ($field.value === '') {
//         $message.style.color = '';
//     } else {
//         $message.style.color = color;
//     }
// };

const checkDate = (date) => {
    const regex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/gm;
    const regex1 = RegExp(regex);
    return regex1.test(date);
};

class FormError extends Error {
    constructor(...args) {
        super(args);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FormError);
        }
    }
}

class Form {
    constructor(node, customOptions = {}) {
        this.$form = node;
        this.options = ObjectAssignDeep.noMutate(Form.DEFAULT_CONFIG, customOptions);

        this.locale = 'fr-FR';
        this.$submit = null;

        this.$fields = [];
        this.$recaptcha = null;
        this.$recaptchaMessage = null;
        this.errors = {};
        this.data = {
            fields: {},
        };
        this.events = {
            [Form.EVENT_SUBMIT]: null,
            [Form.EVENT_SUBMIT_COMPLETE]: null,
            [Form.EVENT_SUBMIT_FAIL]: null,
        };
    }

    init() {
        if (!this.$form) {
            throw new FormError(`Form not found @ form#${this.$form.id}`);
        }

        // INIT FIELDS

        this.$fields = Array.from(this.$form.querySelectorAll('input:not([type="submit"]):not([name="locale"]), textarea, select'));
        this.$recaptcha = this.$form.querySelector('#reCaptcha');
        this.$recaptchaMessage = this.$form.querySelector('#reCaptchaMessage');

        const $locale = this.$form.querySelector('input[name=locale]');
        if ($locale) {
            if (!$locale.value) {
                throw new FormError(`Custom locale is set but invalid @ form#${this.$form.id}`);
            }
            this.locale = $locale.value;
        }

        this.$submit = this.$form.querySelector('input[type=submit]');


        // INIT EVENT LISTENERS
        this.$fields.forEach(($field) => {
            const callback = () => {
                this.validate($field);
            };

            if ($field.type !== 'hidden' && !$field.dataset.code) {
                throw new FormError(`Missing field dataset [data-code] @ form#${this.$form.id} input named "${$field.name}"`);
            }
            $field.addEventListener('blur', callback);
            $field.addEventListener('input', callback);
        });

        this.$form.addEventListener('submit', (e) => {
            e.preventDefault();

            if (this.events[Form.EVENT_SUBMIT]) {
                this.events[Form.EVENT_SUBMIT]();
            }

            this.submit().then((response) => {
                if (this.events[Form.EVENT_SUBMIT_COMPLETE]) {
                    this.events[Form.EVENT_SUBMIT_COMPLETE](response);
                }
            }).catch((error) => {
                if (this.events[Form.EVENT_SUBMIT_FAIL]) {
                    this.events[Form.EVENT_SUBMIT_FAIL](error);
                }
            });
        });
    }

    /**
     * Add a custom event listener
     * @param {Node} $field
     * @return {bool}
     */
    on(name, callback) {
        if (typeof callback !== 'function') {
            throw new FormError(`Invalid callback for "${name}" event @ form#${this.$form.id}`);
        }
        if (!Object.hasOwnProperty.call(this.events, name)) {
            throw new FormError(`Could not add event "${name}" @ form#${this.$form.id}`);
        }

        this.events[name] = callback;
    }

    /**
     * Test a specific field for validation
     * @param {Node} $field
     * @return {bool}
     */
    validate($field) {
        const $parent = $field.parentElement;
        const $message = $parent.nextElementSibling;

        const { code, message, name, value } = this.testField($field); // eslint-disable-line

        // registers new value
        if (name === '') {
            console.log('Field has no name -->', $field); // eslint-disable-line
        } else {
            this.data.fields[name] = value;
        }

        if (code !== 'F1FIELD') { // in case it fails
            if ($message && $message.classList.contains('form-message')) $message.innerHTML = message;
            $parent.classList.add('invalid');
            $parent.classList.remove('valid');
            return false;
        }

        // everything is ok
        if ($message && $message.classList.contains('form-message')) {
            $message.innerHTML = '';
        }
        $parent.classList.remove('invalid');
        $parent.classList.add('valid');

        return true;
    }

    /**
     * Test all fields for validation and returns true if everything is ok
     * @return {bool}
     */
    validateAll() {
        return this.$fields.reduce((bool, $field) => {
            const validated = this.validate($field);
            return bool && validated;
        }, true);
    }

    /**
     * Validates a field and returns validation data
     * @param {Node} $field
     * @return {Object}
     */
    testField($field) {
        const value = escape($field.value);
        const name = $field.name; // eslint-disable-line
        const code = $field.dataset.code; // eslint-disable-line

        if (($field.type === 'hidden' && $field.name !== 'date') && value === '') {
            throw new FormError(`Empty hidden field "${$field.name}" @ form#${this.$form.id}`);
        }
        switch ($field.type) {
        case 'file':
            return Form.feedback(
                code,
                name,
                value,
                $field.required && $field.files.length === 0,
                true,
                'FOR',
            );
        case 'radio':
            return Form.feedback(
                code,
                name,
                value,
                $field.required && !(document.querySelector(`input[name="${name}"]:checked`) !== null),
            );
        case 'checkbox':
            return Form.feedback(
                code,
                name,
                document.querySelector(`input[name="${name}"]:checked`) !== null ? 'oui' : 'non',
                $field.required && !$field.checked,
            );
        case 'email':
            return Form.feedback(
                code,
                name,
                normalizeEmail(value),
                $field.required && value === '',
                isEmail(normalizeEmail(value)),
                'FOR',
            );

        case 'tel':
            return Form.feedback(
                code,
                name,
                value,
                $field.required && value === '',
                isMobilePhone(value, this.locale, this.options.strictMode),
                'FOR',
            );

        default:
            switch ($field.name) {
            case 'date':
                return Form.feedback(
                    code,
                    name,
                    checkDate($field.value),
                    $field.required && value === '',
                    true,
                    'FOR',
                );
            case 'password': // eslint-disable-line
                if ($field.classList.contains('nocheck')) {
                    return Form.feedback(
                        code,
                        name,
                        value,
                        $field.required && value === '',
                        true,
                        'FOR',
                        '',
                    );
                }


                //     const result = zxcvbn(value, passwordMessages[this.locale]); // eslint-disable-line
                //     const strength = getStrength(result.score); // eslint-disable-line
                //     setMessageColor($field, strength.color); // eslint-disable-line
                //     const message = result.score < 3 ? strength.message : '';// eslint-disable-line
                //     // const message = `${result.feedback.suggestions.join(' | ')} <br /> ${result.feedback.warning}`; // eslint-disable-line
                //     return Form.feedback(
                //         code,
                //         name,
                //         value,
                //         $field.required && value === '',
                //         result.score >= 1,
                //         'FOR',
                //         message,
                //     );

            case 'confirmpassword': // eslint-disable-line
                const $passwordField = this.$form.querySelector('input[name="password"]'); // eslint-disable-line
                if (!$passwordField) {
                    throw new FormError(`Password field not found @ form#${this.$form.id}`);
                }
                return Form.feedback(
                    code,
                    name,
                    value,
                    $field.required && value === '',
                    $passwordField.value === $field.value,
                    'DIF',
                );

            case 'zipcode':
                return Form.feedback(
                    code,
                    name,
                    value,
                    $field.required && value === '',
                    value !== '' && isPostalCode(value, this.locale.substring(this.locale.length - 2)),
                    'FOR',
                );

            default:
                return Form.feedback(
                    code,
                    name,
                    value,
                    $field.required && value === '',
                    !$field.pattern || $field.value.match($field.pattern),
                    'FOR',
                );
            }
        }
    }

    /**
     * Triggers form submit
     * @return {Promise}
     */
    submit() {
        return new Promise((resolve, reject) => {
            if (this.validateAll()) {
                if (this.options.async === true) {
                    throw new FormError('Async form submit is not supported in this version');
                } else if (this.$recaptcha !== null) {
                    const response = grecaptcha.getResponse(); //eslint-disable-line
                    if (response.length === 0) {
                        this.$recaptchaMessage.innerHTML = 'Veuillez cocher la case ci-dessus';
                    } else {
                        this.$form.submit();
                        resolve();
                    }
                } else {
                    // normal submit
                    this.$form.submit();
                    resolve();
                }
            } else {
                const error = new FormError(`Invalid form @ form#${this.$form.id}`);
                reject(error);
            }
        });
    }

    /**
     * Applies to all form fields a given key / value
     * @param {string} attribute - HTML attribute
     * @param {any} value
     */
    _applyToAll(attribute, value) {
        if (this.$submit) {
            this.$submit[attribute] = value;
        }

        this.$fields.forEach(($e) => {
            const $field = $e;
            if ($field) {
                $field[attribute] = value;
            }
        });
    }

    /**
     * Disable form
     */
    disable() {
        this.$form.classList.add('disabled');
        this._applyToAll('disabled', true); // eslint-disable-line
    }

    /**
     * Enable form
     */
    enable() {
        this.$form.classList.remove('disabled');
        this._applyToAll('disabled', false); // eslint-disable-line
    }

    /**
     * Returns a feedback object
     * @param {string} Field identifier e.g TEX
     * @param {string} name - Name property
     * @param {any} value - Cleaned up value
     * @param {boolean} required - Result of the required test
     * if true means the field is required and not correctly filled
     * @param {boolean} valid - Result of the validation test
     * @param {string} suffix - Code suffix e.g FOR or REQ
     * @param {string} customMess - Bypass validation message w/ a custom message
     * @return {Object}
     */
    static feedback(field, name, value, required = false, valid = true, suffix = '', customMess = null) {
        let code = 'F1FIELD';
        let message = '';

        if (required) {
            code = `F0${field}REQ`;
            message = Form.MESSAGE_LIST[code] || '';
        } else if (value !== '' && !valid) {
            code = `F0${field}${suffix}`;

            if (!customMess) {
                message = Form.MESSAGE_LIST[code] || '';
            } else {
                message = customMess;
            }
        }

        return {
            code,
            message,
            name,
            value,
        };
    }
}

Form.EVENT_SUBMIT = 'submit';
Form.EVENT_SUBMIT_COMPLETE = 'submit.complete';
Form.EVENT_SUBMIT_FAIL = 'submit.fail';

Form.MESSAGE_LIST = (typeof MESSAGES === 'object') ? MESSAGES : {}; // eslint-disable-line

Form.DEFAULT_CFG = {
    async: true,
    strictMode: false,
};

export { FormError };

export default Form;
