class seeMore {
    constructor(items, toShow) {
        this.items = Array.from(document.querySelectorAll(items));
        this.itemsClass = items;
        this.nbItems = this.items.length;
        this.increment = toShow;
        this.toShow = toShow;
        this.firstItem = 0;
        if (!this.items) {
            throw new Error(`No element with given attribute --> ${items}`);
        }
    }

    showMoreItems() {
        this.toShow = this.toShow > this.nbItems ? this.nbItems : this.toShow;

        for (let i = this.firstItem; i < this.toShow; i += 1) {
            this.items[i].classList.add('show');
        }
        this.firstItem += this.increment;
        this.toShow += this.increment;
    }

    nbShow() {
        return this.items.filter(item => item.classList.contains('show')).length;
    }

    noMoreItems() {
        return this.nbShow() >= this.nbItems;
    }
}

export default seeMore;
