import './dashboard.scss';

document.addEventListener('DOMContentLoaded', () => {
    // toggle
    const fragmentsTriggers = Array.from(document.querySelectorAll('.fragment-toggle'));
    const fragments = [];
    const title = document.querySelector('.fragment-title');

    if (title) {
        title.addEventListener('click', (e) => {
            e.preventDefault();
            title.parentNode.classList.toggle('active');
        });
    }

    fragmentsTriggers.forEach((trigger, i) => {
        const targetName = trigger.dataset.fragment;
        const target = document.querySelector(targetName);

        if (target && target.classList.contains('fragment')) {
            fragments.push(target);

            if (i === 0) { // first element displayed by default
                trigger.classList.add('active');
                target.classList.add('active');
                title.innerHTML = trigger.innerHTML;
            }

            // switch fragment view on click on the related trigger
            trigger.addEventListener('click', (e) => {
                e.preventDefault();

                fragmentsTriggers.filter(node => node !== trigger).forEach(node => node.classList.remove('active'));
                trigger.classList.add('active');

                fragments.filter(node => node !== target).forEach(node => node.classList.remove('active'));
                target.classList.add('active');

                title.innerHTML = trigger.innerHTML;
                title.parentNode.classList.toggle('active');
            });
        }
    });
});
