import '../../parcours/etape1/fiche-centre';

import pinIcon from 'icons/PinGoogleMap.svg'; // eslint-disable-line

import GoogleMapsLoader from 'js/utils/GoogleMapLoader'; // eslint-disable-line

// Personalize map style @ https://mapstyle.withgoogle.com
// import styleCfg from 'json/googleMapStyle.json';

GoogleMapsLoader.load((google) => {
    const node = document.getElementById('fiche-centre-map');
    if (node) {
        const data = JSON.parse(node.dataset.map);
        const coords = { lat: data.lat, lng: data.lng };
        const map = new google.maps.Map(node, {
            center: coords,
            zoom: data.zoom,
        });

        const marker = new google.maps.Marker({ // eslint-disable-line
            position: coords,
            map: map, // eslint-disable-line
            icon: pinIcon,
        });
    }
});
