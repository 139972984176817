
// import anime from 'animejs';
import ShapeOverlays from 'js/ShapeOverlays';

const ready = (callback) => {
    if (document.readyState === 'complete') {
        callback();
    } else {
        document.addEventListener('readystatechange', (e) => {
            if (e.target.readyState === 'complete') {
                callback();
            }
        });
    }
};

const unloadAnim = (callback) => {
    const elmOverlay = document.querySelector('.shape-overlays');
    elmOverlay.style.display = 'block';
    elmOverlay.style.transform = 'rotateY(0) rotateZ(0)';
    elmOverlay.style.top = '0';
    elmOverlay.style.height = '100vh';
    const overlay = new ShapeOverlays(elmOverlay, 'out', () => {
        callback();
    }, () => {

    });
    overlay.toggle();
};

const loadAnim = () => {
    const pageAnimEnd = document.querySelector('.pageanim__endblock');
    const breadcrumbMenu = document.querySelector('.breadcrumb-menu');
    const header = document.querySelector('.header');
    let top = 0;
    let height = '100vh';

    if (pageAnimEnd) {
        pageAnimEnd.classList.add('pageanim__endblock_hide');
        pageAnimEnd.classList.remove('pageanim__endblock_show');
        height = pageAnimEnd.offsetHeight + header.offsetHeight + 90;
        if (breadcrumbMenu) {
            height += breadcrumbMenu.offsetHeight;
        }
        top = height;
    }
    header.classList.add('header_hide');
    header.classList.remove('header_show');
    const elmOverlay = document.querySelector('.shape-overlays');
    elmOverlay.style.height = height;
    elmOverlay.style.top = top;
    document.body.style.visibility = 'visible';

    const overlay = new ShapeOverlays(elmOverlay, 'in', () => {
        elmOverlay.style.display = 'none';
    }, () => {
        if (pageAnimEnd) {
            pageAnimEnd.classList.remove('pageanim__endblock_hide');
            pageAnimEnd.classList.add('pageanim__endblock_show');
        }
        header.classList.remove('header_hide');
        header.classList.add('header_show');
    });
    overlay.toggle();
};

ready(loadAnim);

Array.from(document.querySelectorAll('a:not([download]):not(.tel):not(.email):not([target="_blank"]):not(.menu-trigger):not(.fragment-toggle):not(.anchor)')).forEach((link) => {
    link.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        unloadAnim(() => {
            window.location.assign(link.href, link.target);
        });
    });
});
