/** Plugins */
import 'modern-normalize';
import WebFont from 'webfontloader';

import AOS from 'aos';
import 'aos/dist/aos.css';
import 'js/polyfill'; //eslint-disable-line
/** Global components */
import './src/components/menu';

import './src/components/popup';
/** Global Style */
import 'style/main.scss'; //eslint-disable-line


// auto init opening boxes
import 'style/components/_opening-box.scss'; //eslint-disable-line
import 'js/utils/openingBox'; //eslint-disable-line

// Submenu
import 'js/subMenu';

/** Pages */
import './src/pages/home';
import './src/pages/404';
import './src/pages/about';
import './src/pages/contact';
import './src/pages/guide';
import './src/pages/guide/confirmation';
import './src/pages/contact/confirmation';
import './src/pages/demarche';
import './src/pages/demarche/etape1';
import './src/pages/demarche/etape2';
import './src/pages/demarche/etape3';
import './src/pages/demarche/etape4';
import './src/pages/demarche/etape5';
import './src/pages/actualite';
import './src/pages/actualite-detail';
import './src/pages/testimony';
import './src/pages/account';
import './src/pages/parcours/etape1/trouver-un-centre';
import './src/pages/parcours/etape1/liste-centre';
import './src/pages/parcours/etape1/fiche-centre';
import './src/pages/parcours/etape2/date';
import './src/pages/parcours/etape2/motif';
import './src/pages/parcours/etape2/choix';
import './src/pages/parcours/etape2/client';
import './src/pages/parcours/etape3/inscription';
import './src/pages/parcours/etape3/demande-renvois';
import './src/pages/parcours/etape3/connection';
import './src/pages/parcours/etape4/confirmation';
import './src/pages/aides-auditives';
import './src/pages/aides-auditives/accessoires';
import './src/pages/aides-auditives/produits-entretien';
import './src/pages/aides-auditives/nos-solutions';
import './src/pages/engagements';
import './src/pages/centre/recherche';
import './src/pages/centre/liste';
import './src/pages/centre/fiche';
import './src/pages/dashboard';
import './src/pages/le-reseau';
import './src/pages/mentions-legales';


import datePicker from 'flatpickr';
import Cleave from 'cleave.js';
import { French } from 'flatpickr/dist/l10n/fr';

require('flatpickr/dist/themes/light.css');

// lightbox
import Lightbox from '@novembrecom/nlightbox'; //eslint-disable-line

import Form, { FormError } from 'js/Form/Form'; //eslint-disable-line

AOS.init({
    initClassName: 'aos-init',
});

// Form auto validation
const forms = Array.from(document.querySelectorAll('form'));

forms.forEach((node) => {
    if (node && !node.classList.contains('novalidate')) {
        try {
            const form = new Form(node, {
                async: false,
                strictMode: false,
            });
            form.init();
        } catch (e) {
            if (!(e instanceof FormError)) {
                throw e;
            }
            console.warn(e.message); // eslint-disable-line
        }
    }
    Array.from(node.querySelectorAll('.datepicker')).forEach((datepicker) => {
        datePicker(datepicker, {
            minDate: '1900-01',
            maxDate: 'today',
            firstDayOfWeek: 1,
            dateFormat: 'd/m/Y',
            altInput: true,
            altFormat: 'd/m/Y',
            allowInput: true,
            locale: French,
            onChange(selDates, dateStr) {
                this._selDateStr = dateStr; //eslint-disable-line
            },
            onClose(selectedDates, dateStr, instance) { // eslint-disable-line
                datepicker.blur();
                this._selDateStr = dateStr; //eslint-disable-line
            },
            onReady(selectedDates, dateStr, instance) {
                // eslint-disable-next-line no-new
                new Cleave(this.altInput, {
                    date: true,
                    datePattern: ['d', 'm', 'Y'],
                    delimiter: '/',
                    onValueChanged(e) {
                        instance.jumpToDate(e.target.value);
                    },
                });
                this.altInput.addEventListener('blur', (e) => {
                    instance.setDate(e.target.value);
                });
            },
        });
    });

    Array.from(node.querySelectorAll('.form__select')).forEach((select) => {
        select.addEventListener('change', () => {
            const option = select.querySelector('option:checked');
            const { desc } = option.dataset;
            const $desc = document.querySelector(`.select__description[data-select="${select.id}"`);
            if ($desc) {
                if (desc) {
                    $desc.innerHTML = desc;
                } else {
                    $desc.innerHTML = '';
                }
            }
        });
    });
});

// Fonts
WebFont.load({
    google: {
        families: ['Ubuntu:300,400,700', 'Open Sans:400'],
    },
});


/*
 * Main lightbox object used throughout the pages
 */
window.lb = new Lightbox({
    uid: 1,
    enableBulletlistUI: false,
    enablePaginationUI: false,
    rewind: false,
});
window.lb.init();
