import Stickyfill from 'stickyfilljs';

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = (callback, thisArg) => {
        const newArg = thisArg || window;
        for (let i = 0; i < this.length; i += 1) {
            callback.call(newArg, this[i], i, this);
        }
    };
}

const elements = document.querySelectorAll('.sticky');
Stickyfill.add(elements);
