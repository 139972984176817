import '@glidejs/glide/src/assets/sass/glide.core.scss'; // eslint-disable-line
import './etape3.scss';

import Glide from '@glidejs/glide';

/**
 * COFFRET SLIDER
 * Creates wrapper elements from scratch for Glidejs support
 * and inject the new mounted copied element in the DOM.
 * Displaying either element (mobile/desktop) is handled with CSS.
 */
document.addEventListener('DOMContentLoaded', () => {
    const node = document.getElementById('coffretlist');
    if (node) {
        const nodeCopy = node.cloneNode(true);

        node.classList.add('coffrelist__desktop');
        nodeCopy.id = 'coffrelist-copy';

        // glide main element
        const glideElement = document.createElement('div');
        glideElement.classList.add('glide');
        glideElement.classList.add('coffrelist__mobile');

        // arrows
        const glideArrows = document.createElement('div');
        glideArrows.classList.add('glide__arrows');
        glideArrows.dataset.glideEl = 'controls';
        glideArrows.innerHTML = `
            <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                <i class="icon icon_arrowleft"></i>
            </button>
            <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                <i class="icon icon_arrowright"></i>
            </button>
        `;
        glideElement.appendChild(glideArrows);

        // tracks
        const glideTracks = document.createElement('div');
        glideTracks.classList.add('glide__track');
        glideTracks.dataset.glideEl = 'track';
        glideElement.appendChild(glideTracks);

        // slide content
        nodeCopy.classList.add('glide__slides');
        Array.from(nodeCopy.getElementsByClassName('coffret')).forEach((item) => {
            item.classList.add('glide__slide');
        });
        glideTracks.appendChild(nodeCopy);


        // Glidejs is enabled on the copied element
        const glider = new Glide(glideElement, {
            type: 'carousel',
            startAt: 1,
            perView: 1,
            gap: 18,
            rewind: false,
        });

        node.parentElement.appendChild(glideElement);
        glider.mount();
    }
});
