import GoogleMapsLoader from 'google-maps';

// Google Map API config

// GoogleMapsLoader.KEY = 'AIzaSyC6qVsh3xK-Gdoj-c7lTytdj5m9GvjTSds'; // old AIzaSyD2ScUF8YqoV2Hcul9VHI37vyBG5d88fIo
GoogleMapsLoader.KEY = 'AIzaSyC6qVsh3xK-Gdoj-c7lTytdj5m9GvjTSds'; //
GoogleMapsLoader.LANGUAGE = 'fr';
GoogleMapsLoader.VERSION = '3.34';

export default GoogleMapsLoader;
