import './liste-centre.scss';
import pinIcon from 'icons/PinGoogleMap.svg'; // eslint-disable-line

import GoogleMapsLoader from 'js/utils/GoogleMapLoader'; // eslint-disable-line
// Personalize map style @ https://mapstyle.withgoogle.com
// import styleCfg from 'json/googleMapStyle.json';

GoogleMapsLoader.load((google) => {
    const node = document.getElementById('liste-centre-map');
    if (node) {
        const dataMarkers = JSON.parse(node.dataset.markers);
        const map = new google.maps.Map(node, {
            // disableDefaultUI: true,
            // styles: styleCfg,
        });
        const bounds = new google.maps.LatLngBounds();
        let currentInfo = null;
        dataMarkers.forEach((dataMarker) => {
            const {
                latitude, longitude, name, address, zipcode, city, url,
            } = dataMarker;
            const infowindow = new google.maps.InfoWindow({ //eslint-disable-line
                content: `
                <div itemscope itemtype="http://schema.org/Place" class="liste-centre__infowindow infowindow">
                    <div class="infowindow__name"><span itemprop="name">${name}</span></div>
                    <div class="infowindow__address" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
                        <span itemprop="streetAddress">
                            ${address}
                        </span>
                        <div>
                            <span itemprop="postalCode">${zipcode}</span> <span itemprop="addressLocality">${city}</span>
                        </div>
                    </div>
                    <a class="infowindow__link" href="${url}" itemprop="url">Voir la fiche du centre</a>
                </div>
                `,
            });
            const marker = new google.maps.Marker({ // eslint-disable-line
                position: { lat: latitude, lng: longitude },
                map: map, // eslint-disable-line
                icon: pinIcon,
            });
            const loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(loc);
            marker.addListener('click', () => {
                infowindow.open(map, marker);
                if (currentInfo) {
                    currentInfo.close();
                }
                currentInfo = infowindow;
            });
            map.addListener('click', () => {
                infowindow.close();
                currentInfo = null;
            });
        });
        map.fitBounds(bounds);
        map.panToBounds(bounds);
    }
});
