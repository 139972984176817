import './trouver-un-centre.scss';

document.addEventListener('DOMContentLoaded', () => {
    // Reverse geocoding
    const form = document.getElementById('formSearch');

    if (form) {
        const geolocInput = document.getElementById('localization');
        const latInput = document.getElementById('lat');
        const lngInput = document.getElementById('lng');
        const geolocBtn = document.getElementById('trouver-un-centre-geoloc');
        const geolocErrorMess = document.getElementById('trouver-un-centre-geoloc-error');
        if (geolocInput && !geolocInput.disabled) {
            if (navigator.geolocation) {
                geolocBtn.classList.add('active');

                geolocBtn.addEventListener('click', (e) => {
                    e.preventDefault();

                    geolocErrorMess.innerHTML = '';
                    geolocInput.disabled = true;

                    navigator.geolocation.getCurrentPosition(({ coords }) => {
                        // console.log(coords);
                        latInput.value = coords.latitude;
                        lngInput.value = coords.longitude;
                        form.submit();
                    }, (error) => {
                        switch (error.code) {
                        case error.PERMISSION_DENIED:
                            geolocErrorMess.innerHTML = 'Vous devez autoriser la géolocalisation';
                            break;
                        case error.POSITION_UNAVAILABLE:
                            geolocErrorMess.innerHTML = 'Information de géolocalisation indisponible';
                            break;
                        case error.TIMEOUT:
                            geolocErrorMess.innerHTML = 'La requête a expiré';
                            break;
                        default:
                            geolocErrorMess.innerHTML = 'Une erreur inconnue est survenue';
                            break;
                        }
                        geolocInput.disabled = false;
                    });
                });
            }
        }
    }
});
